html {
  scroll-behavior: smooth; }

header .navbar {
  background: white;
  box-shadow: 0 3px 5px rgba(100, 100, 100, 0.49); }
  header .navbar-brand img {
    height: 50px; }
    @media screen and (max-width: 575px) {
      header .navbar-brand img {
        height: 38px; } }
  @media screen and (max-width: 991px) {
    header .navbar-nav {
      margin-top: 20px; } }
  header .navbar .language-list .language-item {
    margin: 8px 0 8px 10px;
    width: 25px; }
  header .navbar .nav-item .nav-link {
    color: #231f20; }
  header .navbar .nav-item .btn {
    margin-left: 10px; }
  @media screen and (max-width: 991px) {
    header .navbar .nav-item {
      margin: 0px 0px 7px 0px !important; }
      header .navbar .nav-item .btn {
        margin-left: 0px; } }
  header .navbar-toggler {
    border: none;
    background: transparent !important;
    outline: 0;
    padding-left: 0;
    padding-right: 15px; }
    header .navbar-toggler .icon-bar {
      width: 22px;
      height: 2px;
      background-color: #231f20;
      display: block;
      transition: all 0.2s;
      margin-top: 4px; }
    header .navbar-toggler .top-bar {
      transform: rotate(45deg);
      transform-origin: 10% 10%; }
    header .navbar-toggler .middle-bar {
      opacity: 0; }
    header .navbar-toggler .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%; }
    header .navbar-toggler.collapsed .top-bar {
      transform: rotate(0); }
    header .navbar-toggler.collapsed .middle-bar {
      opacity: 1; }
    header .navbar-toggler.collapsed .bottom-bar {
      transform: rotate(0); }
